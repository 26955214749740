/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    h3: "h3",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Custom Prompt"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://zsh.sourceforge.io/Doc/Release/Prompt-Expansion.html#Prompt-Expansion"
  }, "Official Docs - zsh")), "\n", React.createElement(_components.h3, null, "Syntax Quick Ref"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "%K"), "\n: Promp background color\n: Example - ", React.createElement(_components.code, null, "%K{blue}...")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "%n"), "\n: alias for username"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "%m"), "\n: alias for system hostname up to '.'"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "%M"), "\n: alias for full hostname"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "%B"), "\n: start Boldface mode"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "%F"), "\n:"), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://linuxhint.com/change-zsh-prompt-name/#:~:text=How%20to%20Customize%20the%20ZSH,how%20the%20ZSH%20prompt%20appears."
  }, "Change Zsh Promps - linuxhint.com")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://scriptingosx.com/2019/07/moving-to-zsh-06-customizing-the-zsh-prompt/"
  }, " customizing the zsh prompt - scriptingosx.com")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
